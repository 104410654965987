@import "src/assets/scss/_variables.scss";

.upload-sec {
  height: 220px;
  background-color: $black-700;
  border: 1px dashed $primary-color;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .upload-icon-customization {
    font-size: 90px;
    color: $primary-color;
  }
  .fileDrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .upload-text {
    font-weight: 600;
    font-size: 10px;
    color: $primary-color;
  }
}
.upload-text {
  font-weight: 600;
  font-size: 10px;
  color: $primary-color;
}
.fa-duotone {
  // background-image: url("./../images/upload.png");
  color: $primary-color;
  font-size: 70px;
  margin-bottom: 20px;
}
.form-check-input:checked {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}