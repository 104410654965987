@import "src/assets/scss/_variables.scss";

.alert-position {
  position: fixed;
  top: 46px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999 !important;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.custom-alert {
  padding: 4px;
  border-radius: 4px;
}

.success-alert {
  background-color: $white-200;
  color: $success-text;
  border-left: 4px solid $success-color;
}

.danger-alert {
  border-left: 5px solid $danger-color;

  background-color: $danger-color;
  color: $white;
}

/* check circle */
.check-circle {
  background-image: url("../../assets/images/icons/check-circle.png");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  background-size: cover;
}

/* error icon */
.error-icon {
  background-image: url("../../assets/images/icons/error-icon.jpeg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  background-size: cover;
}
