@import "src/assets/scss/_variables.scss";

.dropdown-toggle::after {
  display: none !important;
}

.grid-view ul {
  padding-left: 0.4rem;
}

.folder-icon {
  background-image: url("../images/folder-icon.png");
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.active-view {
  background-color: $primary-color;
  color: $white;
}

.view-icon:hover {
  box-shadow: 0 0.5rem 1rem $black-400 !important;
}

.dropdown-item:hover {
  background-color: $gray-700 !important;
}

.list-view table thead {
  background-color: $primary-color !important;
  color: $white !important;
}

.search-files .form-control {
  background-color: transparent !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 0 0 !important;
}

.dropdown-menu.show {
  border: none !important;
  box-shadow: 0 0.5rem 1rem $black-400 !important;
}

.view-icon-space {
  margin-right: 12px;
}

.nav-tabs .nav-link.active {
  color: $white !important;
  background-color: $primary-color !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  border-radius: 0 !important;
}

.nav-tabs .nav-link {
  font-weight: 500 !important;
  font-size: 15px !important;
  border-radius: 0 !important;
}
.nav-tabs .nav-link {
  color: $black-800 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.nav-tabs .nav-link:not(.active):hover {
  color: $primary-color !important;
  border-radius: 0 !important;
}
.dropdown-icons .fa-solid {
  color: $black-600;
}

.tree-view {
  height: calc(100vh - 65px);
  border-right: 1px solid $border-color;
  background-color: $white;
}

.search-icon {
  color: $gray-1600;
  position: absolute;
  top: 27px;
  left: 13px;
}

.pl-30 {
  padding-left: 30px !important;
}

.search-files {
  color: $gray-1600;
}

@media (max-width: 991.98px) {
  .search-files {
    margin-bottom: 0.5rem;
  }
}

.trash-toggle {
  position: fixed;
  bottom: 0;
  width: 278px;
  padding: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $border-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input.default:checked + .slider {
  background-color: $primary-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tree-view-section {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-item:active {
  background-color: $primary-color !important;
}

.w-150 {
  width: 120px;
}

table {
  white-space: nowrap;
}

.disable {
  pointer-events: none;
  opacity: 0.3;
}

.table-height {
  max-height: calc(100vh - 240px);
}

header .dropdown-menu.show,
.toolbar-buttons .dropdown-menu.show {
  border: none !important;
  padding: 0px;
  box-shadow: 0 0.5rem 1rem $black-400 !important;
  font-size: 15px;
}

.page-link.active,
.active > .page-link {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $white !important;
}

.page-link:focus {
  box-shadow: 0 0 0 0 !important;
}

.page-link {
  color: $primary-color !important;
}

.admin-section-icon {
  cursor: pointer;
}

.tabs-section {
  .nav-tabs .nav-link.active {
    border-bottom: 2px solid $primary-color !important;
    color: $primary-color !important;
    border: none;
    background-color: transparent !important;
    font-weight: 500 !important;
    font-size: 15px !important;
  }

  .nav-tabs .nav-link {
    border: none !important;
    color: $black-800 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
  }

  .accordion-button {
    background: $primary-color !important;
    color: $white !important;
    padding: 15px !important;
    font-size: 13px !important;
  }

  .accordion-button:focus {
    box-shadow: 0 0 0 0 !important;
  }

  .range-accordion .btn-link1 {
    width: 100% !important;
    text-decoration-line: none;
  }

  .accordion-button::after {
    background-image: url(../images/arrow-down.png);
    transform: rotate(-90deg) !important;
  }

  .accordion-button:not(.collapsed)::after {
    transform: rotate(180deg) !important;
    background-image: url(../images/arrow-up.png);
  }

  .accordion-body {
    padding: 16px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 280px;
  background-color: $white;
  position: fixed;
  height: 100%;
  border-right: 1px solid $border-color;
  overflow: auto;
  transition: left 0.3s ease-in-out;
}

.sidebar a {
  display: block;
  color: $gray-1700 !important;
  padding: 10px;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
}

.sidebar a.active {
  background-color: $primary-color !important;
  color: $white !important;
}

.settings.sidebar a.active {
  background-color: $primary-color !important;
  color: $white !important;
}

.group-sidebar-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.sidebar-btn {
  font-size: 13px !important;
  border-color: transparent;
  padding: 4px 7px !important;
}

.sidebar-btn.delete-btn {
  background-color: $danger-color !important;
  border-color: $danger-color !important;
  border-radius: 0;
}

.group-list {
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.hamburger-menu {
  display: none;
}

@media (max-width: 800px) {
  .hamburger-menu {
    display: inline !important;
  }

  .hamburger-btn {
    padding: 10px !important;
  }

  .sidebar {
    display: none;
  }
}

div.content {
  margin-left: 280px;
}

@media screen and (max-width: 800px) {
  .sidebar {
    margin-top: 40px;
    z-index: 111;
    width: 280px;
    position: absolute;
    height: calc(100vh - 110px);
    border-right: 1px solid $border-color;
    transition: 1.5s;
  }

  div.content {
    margin-left: 0;
  }

  .main-mt {
    margin-top: 1px !important;
  }
}

.ng-select.ng-select-single .ng-select-container {
  z-index: 1 !important;
}
.custom-select.ng-select.ng-select-single .ng-select-container {
  z-index: 1 !important;
  // background-color: $primary-color !important;
  width: 210px !important;
}
.autocomplete-container .suggestions-container {
  z-index: 111 !important;
}

.ng-autocomplete {
  width: 100% !important;
  max-width: 600px !important;
  display: table;
  margin: 0 auto;
}

.autocomplete-container .input-container input {
  border: 1px solid $gray-200 !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  border-radius: 0.375rem !important;
}

.autocomplete-container {
  box-shadow: 0 0 0 0 !important;
}

.search-icon-files {
  position: absolute;
  top: -8px;
  left: 10px;
}

.pl-35 {
  padding-left: 35px !important;
}

ngb-progressbar .progress-bar {
  background-color: $primary-color !important;
}

.details.word-break {
  word-break: break-all;
}

.assign-table {
  max-height: 350px;
  overflow: auto;

  table {
    border-radius: 4px;
  }
}

.breadcrumb {
  .breadcrumb-item a {
    color: $primary-color !important;
    text-decoration: none !important;
    font-weight: 500 !important;
  }

  .breadcrumb-operater {
    font-weight: 800;
    font-size: 15px;
  }

  .tootip-sec {
    position: absolute;
    visibility: hidden;
    z-index: 10;
  }

  .more-option:hover .tootip-sec {
    visibility: visible;
  }
}
.context-menu {
  position: absolute;
  border-radius: 4px;
  padding: 0;
  width: auto;
  background-color: $white;
  z-index: 999;
}

.context-menu li {
  padding: 8px 20px;
  font-size: 15px;
  transition: 0.3s ease-in-out;
}
.context-menu li:hover {
  background-color: $gray-700;
  cursor: pointer;
}
.context-menu i {
  width: 20px;
}
// Context css starts
.context-menu {
  position: absolute;
  border-radius: 4px;
  padding: 0;
  width: auto;
  background-color: $white;

  li {
    padding: 8px 20px;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: $gray-700;
      cursor: pointer;
    }
  }

  i {
    width: 20px;
  }

  .fa-solid {
    color: $black-600;
  }

  .cutPaste {
    opacity: 0.3 !important;
    cursor: default !important;
  }

  .class-disable {
    opacity: 0.5;
    pointer-events: none !important;
  }
}

.gsutil-sftp-heading.mt-100 {
  padding: 18px;
}

.gsutil-sftp-heading {
  color: $primary-color;
}
.visibility {
  visibility: hidden;
}

.gsutil-sftp-file {
  h5 {
    color: $gray-1600;
  }

  dd,
  p,
  dt {
    color: $gray-1600;
  }

  dd a {
    text-decoration: none !important;
    color: $primary-color;
  }

  .gsutil ul {
    list-style-type: circle;
  }
}

// Tree view scss starts

.sidebar-tree-view {
  ul li {
    list-style: none;
    padding: 5px 10px;
  }
  .pl-40 {
    padding-left: 40px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding-left: 1rem;
    position: relative;
  }

  li:before {
    content: "";
    position: absolute;
    left: -0.5rem;
    top: 5px;
    bottom: 5px;
    border-left: 1px solid $border-color;
  }

  li:last-child:before {
    border-left: none;
  }

  li.collapsed:before {
    content: "+";
    color: $primary-color;
    cursor: pointer;
  }

  li.collapsed ul {
    display: none;
  }

  li.expanded:before {
    content: "-";
    color: $primary-color;
    cursor: pointer;
  }

  li.expanded ul {
    display: block;
  }
}

.superscript-text {
  color: $gray-1600;
  font-size: 11px;
}
.gap-10 {
  gap: 10px;
}
app-file-manager a {
  color: $primary-color;
}


// .modal-header {
//   padding: 1rem;
//   border-bottom: 1px solid #dee2e6;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }


.btn-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.modal-body {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #444;
  font-weight: 500;
}

.score-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.score-btn {
  width: 40px;
  height: 40px;
  border: 2px solid #dee2e6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
  font-weight: bold;
  color: #666;
  transition: all 0.2s;
}

.score-btn:hover {
  border-color: #1299bd;
  color: #1299bd;
}

.score-btn.active {
  background: #1299bd;
  border-color: #1299bd;
  color: white;
}

textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
  font-size: 0.9rem;
}

textarea:focus {
  outline: none;
  border-color: #1299bd;
  box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
}

.modal-footer {
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}



.selected-file {
  background: #f8f9fa;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  // color: #666;
}

.file-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}
.file-name {
  display: inline-block;
  max-width: 410px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.modal-loader{
  position: absolute;
  right: 50%;
  transform: translate(50%, 0px);
}