.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 2rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 2rem;
}
.mb-2_5 {
    margin-bottom: 2.5rem !important;
}
.mb-120{
    margin-bottom: 120px;
}
.mb-60{
    margin-bottom: 64px;
}

.pr-0 {
    padding-right: 0;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pl-3 {
    padding-left: 1rem;
}

.pl-4 {
    padding-left: 1.25rem;
}

.pl-5 {
    padding-left: 2rem;
}
.p-10{
    padding: 10px;
}

.m-r-10{
    margin-right: 10px;
}