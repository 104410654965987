// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$saas-primary: mat.define-palette(mat.$indigo-palette);
$saas-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$saas-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$saas-theme: mat.define-light-theme(
  (
    color: (
      primary: $saas-primary,
      accent: $saas-accent,
      warn: $saas-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($saas-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "src/assets/scss/_authentication.scss";
@import "src/assets/scss/_buttons.scss";
@import "src/assets/scss/_forms-.scss";
@import "src/assets/scss/_fonts.scss";
@import "src/assets/scss/_colors.scss";
@import "src/assets/scss/_spacings.scss";
@import "src/assets/scss/_alerts.scss";
@import "src/assets/scss/_checkbox.scss";
@import "src/assets/scss/_usermanager.scss";
@import "src/assets/scss/_filemanager.scss";
@import "src/assets/scss/_radiobtn.scss";
@import "src/assets/scss/_upload-file.scss";
@import "src/assets/scss/_animations.scss";
@import "src/assets/scss/_table.scss";
@import "src/assets/scss/_variables.scss";
@import "src/assets/scss/sidebar.scss";
@import "src/assets/scss/_icons.scss";
@import "~animate.css/animate.min.css";

* {
  margin: 0;
}
html,
body {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: $gray-100;
  font-size: 15px;
}

h5,
h6,
h4,
h3 {
  color: $black-600;
}
h6 {
  font-size: 18px !important;
}
h3,
h5 {
  font-size: 18px !important;
}

.heading {
  font-size: 15px !important;
}
.sub-heading {
  font-size: 13px !important;
}
.content {
  font-size: 11px !important;
}
.ng-select span {
  font-size: 13px !important;
}
label {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: $black-600;
}
input,
textarea {
  font-size: 12px !important;
}
.custom-textarea {
  font-size: 18px !important;
}

small {
  font-size: 13px !important;
}
.text-black-0 {
  color: $black-600 !important;
}
.modal-header-font {
  font-size: 18px !important;
}

.cursor {
  cursor: pointer !important;
}

textarea {
  resize: none !important;
}

.toggle-view-off {
  width: 0 !important;
  transition: width 0.2s;
}

.toggle-view-on {
  width: 260px !important;
  transition: width 0.2s;
}

@media (max-width: 799px) {
  .toggle-icon {
    display: none;
  }

  .toggle-view-off {
    width: 260px !important;
    transition: width 0.2s;
  }
}

.filter-dropdown .dropdown-menu {
  width: 500px !important;
}

.filter-dropdown .dropdown-menu.show {
  left: -450px !important;
  box-shadow: 0 1rem 3rem $black-600 !important;
}
.fa-solid {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}
.header-dropdown .dropdown-menu.show {
  top: 10px !important;
}
.profile-dropdown {
  border: 1px solid $primary-color;
  display: inline-block;
  border-radius: 50%;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  background: $white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.autocomplete-container .suggestions-container ul {
  max-height: 190px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  padding: 0 !important;
}

.back-icon {
  position: absolute;
  top: -30px;
  left: 0;
  transition: all 0.3s ease;

  &:hover {
    color: $primary-color;
  }
}

.CodeMirror {
  border: 1px solid $gray-200;
}
.codemirror-container {
  position: relative !important;
  z-index: -1 !important;
}
.CodeMirror-gutters {
  border-right: 1px solid $gray-300 !important;
  background-color: $gray-400 !important;
  white-space: nowrap !important;
}

/* Customize the scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 5px;
  /* width of the scrollbar */
  height: 8px;
  /* height of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-500;
  /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $border-color;
  /* color of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray-600;
  /* color of the handle when hovered */
}

// multiselect dropdown css
.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid $primary-color !important;
  background: $primary-color !important;
  padding: 0 8px !important;
  border-radius: 5px !important;
}
.multiselect-dropdown .dropdown-btn span {
  font-size: 13px !important;
}

.multiselect-item-checkbox {
  font-size: 13px !important;
}
.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: $primary-color !important;
  border: 2px solid $primary-color !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: $primary-color !important;
}
.ng-touched.ng-invalid {
  border-color: $danger-color !important;
}
.data-manager-pagination .pagination {
  margin-bottom: 0 !important;
}
.pagination {
  margin-bottom: 0 !important;
}

.btn-refresh-tabs {
  position: absolute !important;
  top: -83px !important;
  right: 18px !important;
}
.btn-save-tabs {
  position: absolute !important;
  top: -83px !important;
  right: 54px !important;
}

.query-prop-api .btn-save-tabs {
  position: absolute !important;
  top: -83px !important;
  right: 74px !important;
}
.modal-cross-btn {
  background-color: transparent !important;
  border: none !important;
  font-size: 24px !important;
}
.btn-refresh-user-access {
  position: absolute;
  top: -91px;
  right: 18px;
}

.dropdown-item-padd .dropdown-item {
  padding: 0.65rem 1rem !important;
  font-weight: 400 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  font-size: 15px !important;
}
.action-cursor {
  pointer-events: none;
}

.user-access-role-scroller .dropdown-list {
  overflow: hidden !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  right: -6px !important;
}

.as-horizontal > .as-split-gutter > .as-split-gutter-icon {
  background-image: none !important;
}
.as-horizontal > .as-split-gutter {
  background-color: $gray-700 !important;
  &:hover {
    background-color: $primary-color !important;
  }
}
.trash-mode {
  background-color: $danger-color;
  color: $white;
  position: fixed;
  top: 12px;
  z-index: 999;
  right: 125px;
  padding: 8px;
  border-radius: 8px;
}

// **************. sidebar and right part icon styling while opening and closing sections *****************//

.right-part {
  margin-left: 240px !important;
  z-index: 1 !important;
}

@media (max-width: 800px) {
  .right-part {
    margin-left: 0 !important;
    z-index: 1 !important;
  }
}

.queries-right-part {
  margin-left: 240px !important;
}

.sys-classes {
  margin-left: 5px;
  cursor: pointer;
}

.query-header {
  padding: 12px !important;
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.list-details-accordion .card-header {
  padding: 13.5px 16px !important;
  margin-bottom: 0;
  background-color: $primary-color !important;
  border-bottom: 0px solid transparent;
}
.btn-link1 {
  color: $primary-text !important;
  text-decoration: none !important;
  font-weight: 600;
  padding: 5px 10px !important;
  font-size: 15px !important;
  span {
    font-size: 15px !important;
  }
}
.btn-link {
  color: $white !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  padding: 5px 0px !important;
}
.CodeMirror {
  height: calc(100vh - 157px) !important;
}
app-code .CodeMirror {
  height: calc(100vh - 120px) !important;
}
.modal-body .CodeMirror {
  height: calc(100vh - 133px) !important;
}
.json-editor .modal-dialog,
.json-editor .modal-content {
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
}
.full-window .modal-dialog,
.full-window .modal-content {
  width: 100vw !important;
  margin: 0 !important;
}
.custom-full-width .modal-dialog,
.custom-full-width .modal-content {
  height: 100vh !important;
}
.toolbar-group-buttons {
  display: flex;
  gap: 10px;
  top: 76px;
  right: 10px;
  position: fixed;
}
.data-table .toolbar-group-buttons {
  right: 40px;
}

.manage-table .toolbar-group-buttons {
  right: 42px !important;
}
.toolbar-group-buttons .dropdown-menu-right {
  position: absolute !important;
  top: 37px !important;
  right: 13px !important;
  z-index: 1111 !important;
}
.edit-icon {
  color: $primary-color;
}

.db-property-scroller {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 180px);
}
.logo-icon {
  left: 40px;
  top: 30px;
  height: 45px;
}

.searchModel .title {
  transition: 0 ease-in;
  font-weight: 500;
  font-size: 18px;
  color: $primary-color;
}
.searchModel .title:hover {
  text-decoration: underline !important;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-12 {
  font-size: 12px;
}
.fw-500 {
  font-weight: 500;
}
.lh-15 {
  line-height: 18px;
}
@media (max-width: 1320px) {
  .bread-buttons {
    display: flex;
  }
}
table thead {
  background-color: $primary-color !important;
  color: $white !important;
}
.black-updown-icon{
  filter: brightness(1) !important;
}
table thead th {
  font-size: 13px;
  font-weight: 600;
  background-color: $primary-color !important;
  color: $white !important;
}
table td {
  font-size: 11px;
  font-weight: 400;
  span {
    font-size: 11px !important;
  }
}
.modal-header .modal-title {
  font-size: 15px !important;
}

// by default the component is not visible
.spinner-component {
  display: none;
}

// when body has loading class attached the component becomes visible in the right corner of the page
body.loading {
  div.spinner-component {
    display: block;
    position: absolute !important;
    top: 15px;
    right: 65px;
    bottom: 0px;
    width: 24px;
    height: 24px;
    z-index: 999;
  }
}
body.with-ellipses {
  div.spinner-component {
    right: 90px;
  }
}
body.with-dashboard {
  div.spinner-component {
    right: 130px;
  }
}

.loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid $gray-900;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ng-select .ng-select-container {
  border-radius: 0 !important;
  // min-height: 26px !important;
}
.ng-select.ng-select-single .ng-select-container {
  height: 26px !important;
}

.team-members .dropdown,
.users-table .dropdown {
  position: unset !important;
}

@media (max-width: 768px) {
  .toolbar-dropdown .dropdown-menu.show {
    left: -320px !important;
  }
}
::placeholder {
  opacity: 0.3 !important;
  color: currentColor !important;
}
.bg-ED {
  background-color: $gray-1000 !important;
}

// accordian css
.accordion-button::after {
  transition: none !important;
  position: absolute !important;
  right: 8px !important;
  bottom: 16px !important;
}
.accordion-button:not(.collapsed)::after {
  transform: none !important;
  position: absolute !important;
  right: 15px !important;
  bottom: 16px !important;
}
as-split-area {
  overflow: hidden !important;
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 0 !important;
}
.modal-content,
.card,
.form-select {
  border-radius: 0 !important;
}

.form-group-checkbox-header {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      background-color: transparent;
      border: 2px solid $white;
      border-radius: 3px;
      box-shadow: 0 1px 2px $black-100, inset 0px -15px 10px -12px $black-100;
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px !important;
    }
  }
  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 6px;
    height: 13px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.black-updown-icon {
  filter: brightness(0%);
}
.shareFolderModal {
  .dropdown-list {
    overflow-y: hidden !important;
  }
}
.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.text-justify {
  text-align: justify;
}
.readOnly {
  background-color: $border-color !important;
  opacity: 0.7 !important;
}
.btn-sm {
  font-size: 11px !important;
}

.modal-body .as-horizontal > .as-split-gutter {
  height: calc(100vh - 68px) !important;
}

.modal-body .as-horizontal > .as-split-area {
  height: auto !important;
}
.text-decoration {
  text-decoration: underline;
  &:hover {
    color: $primary-color !important;
  }
}
.breadcrumb-item a {
  color: $primary-color !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}
.dashboard-view-btn {
  position: absolute;
  right: 85px;
  top: -46px;
  z-index: 12;
  border-radius: 50% !important;
  border: 1px solid $primary-color !important;
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  i {
    color: $primary-color;
    font-size: 13px;
    margin: 0;
  }
}
.icons-container i {
  margin: 0 10px;
  font-size: 20px;
  color: $white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.bookmark-heading {
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: $primary-color;
  }
}
.sftp-headings {
  color: $primary-color;
}
.alert-info-background {
  padding: 16px !important;
  background-color: $border-color !important;
}
.trending-file-thumbnail {
  padding: 10px;
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  width: 40px;
  margin-right: 10px;
}
.tabs-section .properties .nav-tabs .nav-link.active {
  color: $white !important;
  background-color: $primary-color !important;
  border: 0 !important;
}

.btn-outline-info {
  color: $border-color !important;
  border-color: $border-color !important;
  &:hover,
  &:active {
    color: $white !important;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.search-filter-dropdown {
  border: 1px solid $primary-color !important;
  background-color: $primary-color !important;
  color: $white;
  padding: 4px 8px;
  font-size: 13px;
  margin-left: 28px;
}
.sidebar-btn.delete-btn {
  background-color: $danger-color !important;
  border-color: $danger-color !important;
  border-radius: 0;
}
.theme-green .bs-datepicker-head {
  background-color: $primary-color;
}
.theme-green .bs-datepicker-body table td.week span {
  color: $primary-color;
}
.doc-logo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-card {
  background-color: $primary-color !important;
  color: $white !important;
  border: 1px solid $primary-color !important;
}

.error-box {
  .error-title {
    font-size: 210px;
    color: #2088e6;
    font-weight: 900;
    text-shadow: 4px 4px 0 $white, 6px 6px 0 $primary-color;
    line-height: 210px;
  }
}
.not-found-error-btn {
  color: $white !important;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
}
.doc-response-card {
  display: inline-block;
  border: 1px solid $primary-color !important;
  border-radius: 50%;
  width: 35px;
  padding: 5px 12px;
  background-color: $primary-color !important;
  color: $white;
}

.card-background {
  background-color: $white-100 !important;
}
.exam-type-section .ng-select .ng-select-container {
  border-radius: 0 !important;
}
.dicom-section-height .ng-select .ng-select-container {
  border-radius: 0 !important;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-radius: 0 !important;
}

canvas {
  border: 1px solid $white !important;
}
.image-editor {
  height: calc(100vh - 253px) !important;
  overflow: auto !important;
}

.clinical-info-height {
  height: calc(100vh - 300px);
  overflow: auto;
}
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  position: relative;
  right: 0;
  top: 58% !important;
  color: $gray-1100;
  margin-top: 4px;
  border-style: solid;
  border-width: 6px 6px 0 !important;
  border-color: $gray-1100 transparent;
  content: "";
}

.tfs-modal {
  margin-top: 60px !important;
}

#send-button {
  height: 36px;
  width: 35px;
  border: 1px solid $primary-color;
  cursor: pointer;
}

.multimedia-viewer-carousel {
  background: $black;
}
.hideTags {
  display: none;
}

.multimedia-view {
  width: 100% !important;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}
.multimedia-area {
  width: 100%;
  height: calc(100vh - 330px);
  position: relative;
}

@media (max-width: 1200px) {
  .multimedia-area {
    width: 100%;
    height: calc(100vh - 450px);
  }
  .multimedia-view {
    width: 100% !important;
    height: calc(100vh - 450px);
  }
  .multimedia-scroller {
    height: auto !important;
    overflow: auto;
  }
}

.button-icon-left {
  padding: 10px 15px;
  background: $gray-1900;
  color: $gray-1800;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 100px;
  z-index: 9;
}
.button-icon-right {
  padding: 10px 15px;
  background: $gray-1900;
  color: $gray-1800;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 100px;
  z-index: 9;
}
.button-icon-left.button-icon-right:focus-visible {
  outline: 0 !important;
}

.multimedia-viewer-carousel .disabled {
  background: $gray-2000 !important;
  border: 1px solid $border-color !important;
  opacity: 0.5;
  pointer-events: none;
}

.options-bar {
  position: relative;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white-600;
  display: flex;
  padding: 8px;
}

.options-bar button {
  padding: 5px 10px;
  border: none;
  background-color: $secondar-color;
  color: $white;
  border-radius: 3px;
  cursor: pointer;

  &:active,
  &:hover {
    background-color: $primary-color;
  }
}

.color-pickers {
  border-radius: 50px;
  margin-left: 30px;
}

.image-editor-container nav a:hover,
.shapes li:hover,
.color-pickers a:hover {
  color: $primary-color;
}

.color-pickers a {
  transition: all 0.5s ease-in-out;
}

.image-editor-container nav a:active {
  font-size: 0.5em;
}

.shapes li:active,
.color-pickers a:active {
  font-size: 0.5em;
}

.image-editor-container nav a {
  color: $white;
  transition: all 0.5s ease-in-out;
}

.image-editor-container .dicom-viewer-carousel {
  background: $black;
  // height: calc(100vh - 450px);
}
.image-editor-container .selected {
  background-color: $primary-color !important;
}
.image-editor-container .disabled {
  opacity: 0.6;
}
.image-editor-container .disabled:hover {
  background-color: $secondar-color !important;
}

.dicom-section-height .dicom-viewer-carousel {
  background: $black;
  height: calc(100vh - 310px);
}

.dicom-section-height .disabled {
  background: #c9c9c9 !important;
  border: 1px solid #ccc !important;
  opacity: 0.5;
  pointer-events: none;
}

.dicom-section-height .loader-width {
  width: 500px;
  display: flex;
  position: absolute;
  top: 49%;
  justify-content: center;
  align-items: center;
}

.dicom-section-height .modal {
  z-index: 700 !important;
}

.dicom-section-height .modal-backdrop.show {
  z-index: 700 !important;
}
.form-control:focus {
  border-color: $primary-color !important;
}
.header-actions{
  .custom-select.ng-select.ng-select-single .ng-select-container {
    z-index: 1 !important;
    width: 330px !important;
  }
  .ng-select .ng-clear-wrapper {
    display: none;
  }
}
app-study-report .alert-position {
  top: -68px !important;
  left: -40% !important;

}