@import "src/assets/scss/_variables.scss";

table thead {
  position: sticky !important;
  top: 0 !important;
  background-color: transparent !important;
  color: $white !important;
  z-index: 1 !important;
}
table tr {
  cursor: pointer;
}
