@import "src/assets/scss/_variables.scss";

.btn-primary {
  background-color: $primary-color !important;
  border: $primary-color !important;
  border-radius: 0 !important;
  font-size: 13px !important;
  color: $white !important;
  &:hover {
    box-shadow: 0 0.5rem 1rem $black-400 !important;
  }
  &:focus-visible {
    box-shadow: none !important;
  }
}
.btn-outline-secondary,
.btn-danger {
  border-radius: 0 !important;
  font-size: 13px !important;
}
.btn-close:focus {
  box-shadow: none !important;
}

.btn:first-child:active.ellipsis {
  border-color: transparent !important;
}
.btn-rounded {
  border-radius: 4px !important;
}

.btn:focus-visible {
  box-shadow: none !important;
}
