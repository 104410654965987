@import "src/assets/scss/_variables.scss";
.main-sidebar {
  .sidebar {
    position: fixed;
    top: 63px;
    width: 240px;
    height: 100%;
    border-right: 1px solid $border-color;
    z-index: 1;
    transition: all 0.3s ease;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li a {
        display: block;
        padding: 10px;
        color: #555;
        text-decoration: none;
        transition: all 0.3s ease;

        & :hover {
          background-color: $white;
          color: $primary-color;
        }
      }
    }
  }

  .sidebar.show {
    left: -16rem;
  }

  .delete-icon {
    font-size: 14px;
    cursor: pointer;
  }
}

.main-sidebar-icon {
  font-size: 20px;
  cursor: pointer;
}

.sidebar-icon-close {
  position: absolute;
  top: 380px;
  left: -17px;
  background: $primary-color;
  color: $white;
  height: 70px;
  width: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  z-index: 10;
  transition: 0.3s ease-in;

  .sidebar-icon-close i {
    position: absolute;
    top: 27px;
    left: 19px;
    font-size: 13px;
  }

  &:hover {
    box-shadow: 0.063rem 0 1.25rem 0 $primary-color;
  }
}

.sidebar-icon-close i {
  position: absolute;
  top: 27px;
  left: 19px;
  font-size: 13px;
}

.sidebar-icon-open {
  display: none !important;
}
.sidebar:hover .sidebar-icon-open {
  position: absolute !important;
  background-color: $primary-color;
  color: $primary-color;
  display: block !important;
  right: -17px;
  z-index: 99999;
  top: 380px;
  height: 70px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  border-radius: 100px;
}

.sidebar-icon-open {
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0.063rem 0 1.25rem 0 $primary-color;
  }
}

.sidebar-icon-open i {
  position: absolute;
  top: 26px;
  left: 6px;
  font-size: 13px;
}

.query-header {
  padding: 12px !important;
}

.sidenav {
  background-color: $white;
  transition: all 0.1s ease;
  position: fixed;
  z-index: 11;
  top: 62\px;
  width: 0;
  height: 100vh;

  .logo-container {
    display: flex;
    align-items: center;
    padding: 1rem 0.938rem 0.88rem 0.238rem;
    width: 100%;
    border-bottom: 1px solid $black-500 !important;
    .logo {
      background-color: $white;
      text-align: center;
      width: 2.5rem;
      min-width: 2.5rem;
      border-radius: 0.313rem;
      color: $white;
      // padding: 0.313rem;
      font-size: 20px;
      font-weight: 900;
      cursor: pointer;
      border: none;
    }

    .logo-text {
      margin-left: 3.5rem;
      font-size: 24px;
      font-weight: 700;
      color: $white;
      top: 8px;
      position: absolute;
    }
    .btn-close {
      margin-left: 3rem;
      cursor: pointer;
      width: 2rem;
      border-radius: 50%;
      background-color: transparent;
      border: none;
      .fa-solid {
        color: $white;
        font-size: 24px;
      }
    }
  }
}

.sidenav-collapsed {
  width: 240px;
}
.sidenav-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px 0;
  align-items: center;
  height: calc(100% - 3.65rem);
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  border-right: 1px solid $black-500;

  .sidenav-nav-item {
    width: 100%;
    padding: 5px 0;
    .sidenav-nav-link {
      // border-left: 5px solid transparent;
      display: flex;
      align-items: center;
      padding: 8px 0;
      color: $black-600 !important;
      text-decoration: none;
      transition: all 0.5s ease;
      white-space: nowrap;

      .sidenav-link-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
        min-width: 2rem;
        margin: 0 8px;
        text-align: center;
        line-height: 1;
        color: $gray-1500;
      }

      .sidenav-link-text {
        font-size: 15px;
      }

      &:hover:not(.active) {
        background-color: $white-300 !important;
        .sidenav-link-icon,
        .sidenav-link-text {
          color: $black !important;
        }
      }
    }

    .active {
      background-color: $white-400;
      border-left: 5px solid $primary-color;
      .sidenav-link-icon,
      .sidenav-link-text {
        color: $primary-color !important;
      }
    }
  }
}

.custom-seperator {
  border-top: 1px solid $white-500;
  margin: 5px 10px;
  padding: 0 !important;
}
.sidenav-collapsed.sidenav-collapsed-admin {
  width: 240px;
  height: 100vh;
}
.sidenav-collapsed-admin {
  width: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  width: 100%;

  .logo {
    background-color: transparent;
    text-align: center;
    // width: 2.5rem;
    // min-width: 2.5rem;
    border-radius: 0.313rem;
    color: $white;
    // padding: 0.313rem;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    border: none;
  }

  .logo-text {
    margin-left: 1rem;
    font-size: 21px;
    font-weight: 700;
    color: $white-500;
    top: 0;
    position: relative;
  }
  .btn-close {
    margin-left: 3rem;
    cursor: pointer;
    width: 2rem;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    .fa-solid {
      color: $white-500;
      font-size: 24px;
    }
  }
}
