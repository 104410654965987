@import "src/assets/scss/_variables.scss";

.bg-trillo {
  background-color: $primary-color;
  // filter: brightness(0.8);
}
.authentication-body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/Login-dicom.png);
  padding: 30px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $primary-color;
  // filter: brightness(0.8);
}
.authentication-cards {
  a {
    text-decoration: none !important;
    color: $primary-color !important;
    font-weight: 500;
  }
  .form-check-input[type="checkbox"] {
    font-size: 16px !important;
    border-color: $primary-color !important;
    border-radius: 2px !important;
    margin-top: 3px !important;
  }
  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
.logo-text-dark5 {
  background-image: url("../../assets/images/logo-text-dark5.png");
  background-repeat: no-repeat;
  display: block;
  height: 60px;
  width: 140px;
  background-size: contain;
  top: 30px;
  left: 30px;
  position: absolute;
  cursor: pointer;
}

.container {
  max-width: 550px !important;
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 10px $black-200;
  ::placeholder {
    font-size: 14px;
  }

  .form {
    height: 100%;
    width: 100%;
    background-color: $white;
  }

  .img-section {
    border-radius: 10px 0 0 10px;
    background-color: $gray-600;
  }
}

form .form-content .title {
  font-size: 24px;
  font-weight: 500;
  color: $gray-600;
}

form .signup-form .title::before {
  width: 20px;
}

form .form-content .input-boxes {
  margin-top: 30px;
}

form .form-content .input-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  position: relative;

  input {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 17px;
    font-weight: 500;
    transition: all 0.3s ease;
  }

  i {
    position: absolute;
    color: $primary-color;
    font-size: 16px;
  }
}

.form-content .input-box input:focus,
.form-content .input-box input:valid {
  border-color: $primary-color;
}

form .form-content .text {
  font-size: 14px;
  font-weight: 500;
  color: $gray-600;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

form .form-content .button {
  color: $white;
  margin-top: 10px;

  input {
    color: $white;
    background: $primary-color;
    padding: 0;
    border-radius: 6px;
    transition: all 0.4s ease;

    &:hover {
      background: $primary-color;
    }
  }
}

form .form-content label {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.orgs-list {
  transition: 0.5s ease;
  width: 75%;
}
.orgs-list:hover {
  transform: scale(1.02);
  cursor: pointer;
  background-color: $primary-color;
  color: $white;
}
.orgs-sec {
  max-height: 300px;
  overflow: auto;
}

.mailbox {
  max-width: 500px !important;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px $black-200;
  text-align: center;
  padding: 10px;
}
.input-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 8px 0 10px 0;
  position: relative;

  input {
    height: 100%;
    width: 100%;
    outline: none;
    padding: 0 40px;
    font-size: 17px;
    font-weight: 500;
    height: 100%;
    border-color: $input-border;
    background: $black-300;
  }
}
