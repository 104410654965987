.zoom-in-out-box {
    animation: zoom-in-zoom-out 0.5s ease ;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(0.8, 0.8);
    }
   
    100% {
      transform: scale(1, 1);
    }
  }