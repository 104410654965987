@import "src/assets/scss/_variables.scss";

.bg-7d2ae8 {
  background-color: $primary-color;
}
table th.bg-EDEDED,
table td.bg-EDEDED {
  background-color: $gray-1400;
}
table td.bg-Blue {
  background-color: $primary-color;
}
table td.primary-text {
  color: $primary-color !important;
}
.bg-EDEDED {
  background-color: $gray-1400;
}

.item-right-clicked {
  outline: 1px solid $primary-color;
  background-color: $gray-1400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #455a64;
}
p,
li,
dd,
dt {
  color: #67757c;
}

.content ul li a {
  color: $primary-color;
}
.text-1F88E6 {
  color: $primary-color !important;
}
.bg-1F88E6 {
  background-color: $primary-color;
}
.text-455a64 {
  color: $black-600;
}
.selected {
  background-color: $primary-color !important;
  color: $white !important;
}
// .selectedRow {
//   background-color: $primary-color !important;
//   color: $white !important;
// }
