$primary-color: #0e99bc;
$secondar-color: #6c757d;
$white: #fff;

$warning-color: #f9c22e;
$danger-color: #dc3545;
$success-color: #4bae6c;
$border-color: #ccc;

$input-border: #b9b4f5;

$primary-text: #212529;
$success-text: #155724;

$white: #fff;
$white-100: rgba(255, 255, 255, 0.6);
$white-200: #d4edda;
$white-300: rgb(235 235 235);
$white-400: #f1f5fe;
$white-500: #a4adad;
$white-600: rgba(222, 217, 217, 0.8);

// $table-head: #56a0b5;
$table-head: #26969d;

$sidebar: #03728e;
$header: linear-gradient(90deg, #20c997, #56a0b5);

// gray colors
$gray-100: #f3f3f3;
$gray-200: #ced4da;
$gray-300: #ddd;
$gray-400: #f7f7f7;
$gray-500: #f1f1f1;
$gray-600: #555;
$gray-700: #eee;
$gray-800: #f8f8f8;
$gray-900: #6d757d;
$gray-1000: #ededed;
$gray-1100: #999;
$gray-1200: #5d5c5c;
$gray-1300: #333435;
$gray-1400: #ededed;
$gray-1500: #777777;
$gray-1600: #7a838a;
$gray-1700: #313131;
$gray-1800: #67757c;
$gray-1900: #d9d9d9;
$gray-2000: #c9c9c9;

// black
$black: #000;
$black-100: rgba(0, 0, 0, 0.05);
$black-200: rgb(0 0 0 / 20%);
$black-300: rgba(128, 152, 249, 0.18);
$black-400: rgb(0 0 0 / 15%);
$black-500: rgba(0, 0, 0, 0.12);
$black-600: rgba(0, 0, 0, 0.66);
$black-700: rgba(30, 136, 230, 0.059);
$black-800: #41464b;
